import { Heading } from '@chakra-ui/react'
import React from 'react'

export function QuizHeading() {
  return (
    <Heading
      textStyle={'heading'}
      color={'#7B7997'}
      fontSize={['44px', '56px']}
      lineHeight={1}
      gridArea={'header'}
    >
      Karma Quiz:
      <br />
      Myth or Fact?
    </Heading>
  )
}
