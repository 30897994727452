import React from 'react'
import { Button, Heading, Stack, Text } from '@chakra-ui/react'
import { useEvents } from '../../../../api/events'
import { BaseLink } from '../../../elements/link'
import MotionBox from '../../../elements/motionBox'

export function QuizEvents() {
  const { data: { current: events = [] } = {}, isSuccess } = useEvents()

  return (
    <MotionBox
      gridArea={'events'}
      alignSelf={'center'}
      pt={{ base: 8, lg: 0 }}
      maxWidth={['320px']}
      justifySelf={'center'}
      initial={'hide'}
      animate={isSuccess ? 'show' : 'hide'}
      variants={{ hide: { opacity: 0 }, show: { opacity: 1 } }}
    >
      <Heading color={'#7B7997'} mb={2}>
        Karma
        <br />
        Virtual Book Tour
      </Heading>
      <Stack direction={'column'} spacing={4}>
        {events.slice(0, 2).map((event) => (
          <div key={event.eventId}>
            <Heading fontSize={'2xl'} color={'gold'}>
              {event.title}
            </Heading>
            <Text color={'#b0aed2'}>
              {event.subText}
              <br />
              {event.dateText}
            </Text>
          </div>
        ))}
        <Button
          variant={'solid'}
          as={BaseLink}
          href={'/events/'}
          maxW={'250px'}
        >
          Get Tickets
        </Button>
      </Stack>
    </MotionBox>
  )
}
