export const quizQuestionsRaw = [
  {
    statement: 'Karma means... "What goes around comes around"',
    type: 'Myth',
    quote: 'Karma means you are the maker of your life.',
  },
  {
    statement:
      'There is a way to tell the difference between good & bad karma.',
    type: 'Fact',
    quote:
      'Whatever you do, just check – is it all about you, or is it for the wellbeing of All. This settles any confusion about good and bad karma.',
  },
  {
    statement: 'My karma & destiny are predetermined.',
    type: 'Myth',
    quote:
      'Karma functions through certain tendencies. But with some awareness and focus, you can push it in the direction that you wish.',
  },
  {
    statement: "Once I've gathered bad karma, I'm doomed!",
    type: 'Myth',
    quote:
      'No matter what kind of karma you gathered in the past, this moment’s karma is always in your hands.',
  },
  {
    statement: 'A Karma Yogi is someone who works joyfully.',
    type: 'Fact',
    quote:
      'Whatever physical activity you do – if you do it with involvement and joy, you are a karma yogi.',
  },
  {
    statement: "No matter what I do, I'll always create more karma.",
    type: 'Myth',
    quote: 'Conscious action does not produce karma – reaction does.',
  },
  {
    statement:
      'Karma can bring misery, but it could also work to my advantage!',
    type: 'Fact',
    quote:
      'Karma means your life is your making. Karmic accumulation can either be a Boost or Burden – that is your choice.',
  },
  {
    statement:
      'Karma is created every moment in every action physically, mentally, emotionally & energetically.',
    type: 'Fact',
    quote:
      'Every moment of your life, you perform action – physically, mentally, emotionally, and energy-wise. Each action creates a certain memory. That is karma.',
  },
  {
    statement: "There's nothing I can do about my Karma.",
    type: 'Myth',
    quote: 'If you become truly meditative, you will be beyond karma.',
  },
  {
    statement: 'Yoga can be a way to break free from karmic patterns.',
    type: 'Fact',
    quote:
      'Karma is like old recordings that keep replaying. Yoga means to make life not just a replay but a profound possibility and experience.',
  },
]
