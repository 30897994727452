import React, { useReducer } from 'react'
import { Box, Grid } from '@chakra-ui/react'
import { cardHeight, cardWidth, NUM_QUESTIONS_TO_ASK } from './quizCardElements'
import { QuizCard } from './quizCard'
import { QuizHeading } from './quizElements'
import QuizResults from './quizResults'
import { getInitialState, reducer } from './quizLogic'
import { QuizEvents } from './quizEvents'

export const GridContainer = (props) => {
  const templateAreas = {
    base: `"header"
           "quiz"
           "events"`,
    lg: `"header header header"
         "side quiz events"`,
  }

  return (
    <Grid
      templateAreas={templateAreas}
      templateColumns={{ base: '1fr', lg: '1fr auto 1fr' }}
      columnGap={8}
      rowGap={6}
      pt={[8, 20]}
      pb={[20, 28]}
      px={[4, 8]}
      {...props}
    />
  )
}

export const QuizSectionMarkup = ({
  state,
  dispatch,
  numQuestions,
  variant,
}) => (
  <Box bgColor='#433F68' position='relative' id={'quiz'} overflow={'hidden'}>
    <GridContainer>
      <QuizHeading />
      <Box
        position='relative'
        width={cardWidth}
        height={cardHeight}
        sx={{ perspective: '20cm' }}
        gridArea={'quiz'}
        justifySelf={'center'}
        // Pass events down to quiz results
        pointerEvents={'none'}
      >
        {state.questions.map((q, index) => {
          return (
            <QuizCard
              key={q.statement}
              q={q}
              index={index}
              isLastCard={state.isLastCard}
              dispatch={dispatch}
              status={state.cards[index].status}
              offset={state.cards[index].offset}
              isCorrect={state.answers[index]}
            />
          )
        })}
      </Box>
      {state.results.show && (
        <QuizResults
          numCorrect={state.results.numCorrect}
          numQuestions={numQuestions}
          showLinkToMainPage={variant === 'page'}
          onRetake={() => dispatch({ type: 'retake' })}
        />
      )}
      {variant === 'page' && <QuizEvents />}
    </GridContainer>
  </Box>
)

export const QuizSection = ({
  numQuestions = NUM_QUESTIONS_TO_ASK,
  variant = 'default',
}) => {
  const [state, dispatch] = useReducer(reducer, numQuestions, getInitialState)

  return (
    <QuizSectionMarkup
      state={state}
      dispatch={dispatch}
      numQuestions={numQuestions}
      variant={variant}
    />
  )
}
