import WhatsappIcon from '../icons/WhatsappIcon'
import {
  getFacebookShareUrl,
  getTwitterShareUrl,
  getWhatsappShareUrl,
} from '../../utils/shareUrls'
import { FacebookIcon, TwitterIcon } from '../icons'
import { HStack, IconButton } from '@chakra-ui/react'
import React, { forwardRef } from 'react'
import { BaseLink } from '../elements/link'
import { motion } from 'framer-motion'
import MotionBox from '../elements/motionBox'

export const shareButtonVariants = {
  initial: {
    opacity: 0,
    y: 30,
  },
  show: { opacity: 1, y: 0 },
}

const ShareButton = forwardRef(({ icon, url, label, ...props }, ref) => (
  <IconButton
    ref={ref}
    as={BaseLink}
    aria-label={label}
    isExternal={true}
    href={url}
    variant={'outline'}
    isRound={true}
    icon={icon}
    colorScheme={'gold'}
    fontSize={'30px'}
    width={'60px'}
    height={'60px'}
    _hover={{ bg: 'hoverGold' }}
    {...props}
  />
))
ShareButton.displayName = 'ShareButton'

const MotionShareButton = motion(ShareButton)

export default function ShareButtons({ shareText, url, hashtags }) {
  const isShareTextObject = typeof shareText === 'object'
  const whatsappShareText = isShareTextObject ? shareText.whatsapp : shareText
  const twitterShareText = isShareTextObject ? shareText.twitter : shareText

  return (
    <MotionBox
      as={HStack}
      initial={'initial'}
      animate={'show'}
      variants={shareButtonVariants}
      justifyContent={'center'}
      spacing={4}
      mt={6}
    >
      {[
        {
          icon: <WhatsappIcon />,
          label: 'Whatsapp share',
          url: getWhatsappShareUrl({ text: whatsappShareText, url }),
        },
        {
          icon: <FacebookIcon />,
          label: 'Facebook share',
          url: getFacebookShareUrl({ url }),
        },
        {
          icon: <TwitterIcon />,
          label: 'Twitter share',
          url: getTwitterShareUrl({ text: twitterShareText, url, hashtags }),
        },
      ].map(({ icon, label, url }, index) => (
        <MotionShareButton icon={icon} label={label} url={url} key={index} />
      ))}
    </MotionBox>
  )
}
