import MotionBox from '../../../elements/motionBox'
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Spacer,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { useWindowSize } from 'react-use'

export const NUM_QUESTIONS_TO_ASK = 7
export const faceUpY = 0
export const faceDownY = 180

export const cardWidth = { base: '280px', sm: '375px' }
export const cardHeight = { base: '370px', sm: '551px' }

const offset = (arr, amount) => arr.map((x) => x + amount)

const useVariants = (type, yOffset, zOffset) => {
  const { width } = useWindowSize()
  const zValues = offset([0, 15, 180, 200, 180, 12, 3, 5, 3], zOffset)
  const yValues = offset([0, -50, 0, 0], yOffset)
  const questionVariants = {
    question: {
      rotateY: 0,
      y: yOffset,
    },
    answer: {
      rotateY: [0, -5, -175, -180, -180],
      y: yValues,
      z: zValues,
    },
    out: {
      z: offset([0, 80, 100], zOffset),
      y: yOffset,
      x: width * 0.6 + 300,
      transition: { type: 'spring', stiffness: 35 },
      rotateY: -180,
    },
  }

  const answerVariants = {
    question: {
      rotateY: 180,
    },
    answer: {
      rotateY: offset(questionVariants.answer.rotateY, 180),
      y: yValues,
      z: zValues,
    },
    out: {
      ...questionVariants.out,
      rotateY: 0,
    },
  }
  return type === 'question' ? questionVariants : answerVariants
}

export const QuizCardSide = ({
  type,
  data,
  onClick,
  index,
  status,
  offset,
  isCorrect,
}) => {
  const isQuestion = type === 'question'
  const isAnswer = type === 'answer'

  const zOffset = -4 * index
  const yOffset = 6 * index
  const variants = useVariants(type, yOffset, zOffset)

  return (
    <MotionBox
      variants={variants}
      animate={status}
      style={{
        // Choose z indices such that the answer is
        // always under the question, and questions with
        // higher indices are below questions with lower indices.
        zIndex: 200 - 2 * index - (isQuestion ? 1 : 0),
        x: 0,
        z: zOffset,
        rotateX: 5,
        rotateY: isQuestion ? 0 : 180,
        ...offset,
      }}
      position={'absolute'}
      sx={{ backfaceVisibility: 'hidden' }}
      top={0}
      borderColor='gold'
      borderRadius={'15px'}
      borderWidth={'1px'}
      overflow={'hidden'}
      bgColor={isQuestion ? 'blue' : 'white'}
      boxShadow={'rgb(0 0 0 / 36%) 1px 6px 9px -2px'}
      pointerEvents={'auto'}
    >
      <Flex
        flexDirection={'column'}
        position={'relative'}
        py={4}
        px={5}
        width={cardWidth}
        height={cardHeight}
        display={'flex'}
      >
        <QuizCardHeader />

        {isAnswer && (
          <>
            <Heading
              color='black'
              fontSize={{ base: '2xl', sm: '4xl' }}
              mb={'.5em'}
            >
              {isCorrect ? <>&#10003;</> : <>&#10008;</>} {data.type}
            </Heading>
          </>
        )}

        <Heading
          color={'gold'}
          fontSize={
            isQuestion ? { base: '2xl', sm: '4xl' } : { base: 'xl', sm: '3xl' }
          }
        >
          {isQuestion && data.statement}
          {isAnswer && (
            <>
              “{data.quote}”<Box mt={4}>-Sadhguru</Box>
            </>
          )}
        </Heading>
        <Spacer />
        <HStack spacing={[5, 8]} justifyContent={'center'}>
          {isAnswer && (
            <QuizCardButton onClick={onClick}>
              {index === NUM_QUESTIONS_TO_ASK - 1 ? 'RESULTS' : 'NEXT'}
            </QuizCardButton>
          )}
          {isQuestion && (
            <>
              <QuizCardButton onClick={() => onClick('Myth')}>
                MYTH
              </QuizCardButton>
              <QuizCardButton onClick={() => onClick('Fact')}>
                FACT
              </QuizCardButton>
            </>
          )}
        </HStack>
      </Flex>
    </MotionBox>
  )
}

const QuizCardButton = ({ children, onClick }) => (
  <Button
    variant='outline'
    borderColor='gold'
    color='gold'
    size={'lg'}
    height={[10, 12]}
    onClick={onClick}
  >
    {children}
  </Button>
)

const QuizCardHeader = () => {
  return (
    <>
      <Flex justifyContent={'space-between'} pb={4} alignItems={'baseline'}>
        <Heading color='gold' fontSize={{ base: 'xl', sm: '2xl' }}>
          KARMA
        </Heading>
        <Text color='gold' fontSize={{ base: 'sm', sm: 'lg' }}>
          MYTH OR FACT
        </Text>
      </Flex>
      <Box borderBottom={'solid 1px'} borderColor={'gold'} mb={3} mx={-2} />
    </>
  )
}
