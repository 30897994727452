export function getTwitterShareUrl({ text, url, hashtags }) {
  const queryParams = ['text=' + encodeURIComponent(text)]
  if (url) queryParams.push('url=' + encodeURIComponent(url))
  if (hashtags) queryParams.push('hashtags=' + encodeURIComponent(hashtags))

  return `https://twitter.com/intent/tweet?` + queryParams.join('&')
}

export function getWhatsappShareUrl({ text, url }) {
  return `https://wa.me/?text=${encodeURIComponent(
    url ? `${text}\n\n${url}` : text
  )}`
}

export function getFacebookShareUrl({ url }) {
  return `https://facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`
}
