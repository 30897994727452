import { quizQuestionsRaw } from './quizQuestions'

function getRandomBetween(min, max) {
  return Math.random() * (max - min) + min
}

const chooseRandom = (arr, num = 1) => {
  const res = []
  for (let i = 0; i < num; ) {
    const random = Math.floor(Math.random() * arr.length)
    if (res.indexOf(arr[random]) !== -1) {
      continue
    }
    res.push(arr[random])
    i++
  }
  return res
}
export const getInitialState = (numQuestions) => {
  return {
    questions: chooseRandom(quizQuestionsRaw, numQuestions),
    cards: Array.from({ length: numQuestions }, () => ({
      status: 'question',
      offset: { rotateZ: getRandomBetween(-1, 1) },
    })),
    answers: Array.from({ length: numQuestions }, () => null),
    currentCard: 0,
    isLastCard: false,
    results: {
      show: false,
      numCorrect: 0,
    },
  }
}
export const reducer = (state, action) => {
  switch (action.type) {
    case 'answer': {
      const newCards = [...state.cards]
      newCards[state.currentCard] = {
        ...state.cards[state.currentCard],
        status: 'answer',
      }

      const newAnswers = [...state.answers]
      // Record if the answer is correct
      newAnswers[state.currentCard] =
        action.payload === state.questions[state.currentCard].type

      return { ...state, cards: newCards, answers: newAnswers }
    }

    case 'next': {
      const newCards = [...state.cards]
      newCards[state.currentCard] = { status: 'out' }
      const newCurrentCard = state.currentCard + 1
      return {
        ...state,
        cards: newCards,
        currentCard: newCurrentCard,
        isLastCard: newCurrentCard >= newCards.length - 1,
        ...(newCurrentCard === newCards.length && {
          results: {
            show: true,
            numCorrect: state.answers.filter((x) => x).length,
          },
        }),
      }
    }

    case 'retake': {
      return getInitialState(state.questions.length)
    }
  }
  console.error('Unexpected action name', action.name)
}
