import { QuizCardSide } from './quizCardElements'
import React from 'react'

export function QuizCard({
  q,
  index,
  dispatch,
  status,
  offset,
  isLastCard,
  isCorrect,
}) {
  const commonProps = {
    data: q,
    index,
    status,
    offset,
  }
  return (
    <>
      <QuizCardSide
        type='question'
        onClick={(payload) => dispatch({ type: 'answer', payload })}
        {...commonProps}
      />
      <QuizCardSide
        type={'answer'}
        onClick={() => dispatch({ type: 'next' })}
        isLastCard={isLastCard}
        isCorrect={isCorrect}
        {...commonProps}
      />
    </>
  )
}
