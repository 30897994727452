import React from 'react'
import { Icon } from '@chakra-ui/react'

const WhatsappIcon = (props) => (
  <Icon viewBox='0 0 32.66 32.66' {...props}>
    <path
      fill={'currentColor'}
      d='M16.33,0A16.33,16.33,0,0,0,1.63,23.44L.87,27.72,0,32.5l4.8-.71,4.6-.67A16.33,16.33,0,1,0,16.33,0Zm0,29.16A12.79,12.79,0,0,1,10,27.5l-5.71.83,1-5.47A12.69,12.69,0,0,1,3.5,16.33,12.83,12.83,0,1,1,16.33,29.16Z'
    />
    <path
      fill={'currentColor'}
      d='M22.68,8.51a4.1,4.1,0,0,0-1.61-.36.67.67,0,0,0-.25,0c-.06,0-.14.11-.27.28s-.25.39-.4.65l-.42.76c-.14.24-.27.49-.4.73s-.21.39-.24.45l-.21.3c-.1.15-.18.29-.24.4a.88.88,0,0,0-.08.34.94.94,0,0,0,.33.58,5.42,5.42,0,0,0,.72.63,6.17,6.17,0,0,1,.72.62.86.86,0,0,1,.33.53.78.78,0,0,1-.06.27c0,.1-.07.18-.1.24l-.16.27a2.27,2.27,0,0,1-.13.23,12.42,12.42,0,0,1-2,2.72,12.42,12.42,0,0,1-2.72,2l-.23.14L15,20.5l-.25.1a.69.69,0,0,1-.26.06c-.17,0-.4-.18-.7-.53l-.91-1.05q-.47-.51-.75-.51a.86.86,0,0,0-.34.07,2.78,2.78,0,0,0-.4.25l-.3.2-.85.46c-.56.3-1,.57-1.41.8a1.78,1.78,0,0,0-.62.47.77.77,0,0,0,0,.25,4.1,4.1,0,0,0,.36,1.61A2.85,2.85,0,0,0,10,24a5.13,5.13,0,0,0,2.09.54,3.44,3.44,0,0,0,.54,0,4.56,4.56,0,0,0,.49-.08,3.47,3.47,0,0,0,.52-.14l.48-.16.52-.2.48-.17a14.42,14.42,0,0,0,5.12-3.46,14.29,14.29,0,0,0,3.46-5.12l.17-.48c.1-.25.16-.43.2-.52s.09-.26.16-.47.12-.4.15-.53.05-.3.07-.49a3.44,3.44,0,0,0,0-.54A5.13,5.13,0,0,0,24,10,2.85,2.85,0,0,0,22.68,8.51Z'
    />
  </Icon>
)

export default WhatsappIcon
