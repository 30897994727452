import React, { forwardRef } from 'react'
import { Heading, Text, HStack, Button, Link } from '@chakra-ui/react'
import MotionBox from '../../../elements/motionBox'
import { BaseLink } from '../../../elements/link'
import TextContainer from '../../../elements/textContainer'
import { motion } from 'framer-motion'
import { RepeatIcon } from '@chakra-ui/icons'
import { cardWidth } from './quizCardElements'
import ShareButtons, {
  shareButtonVariants,
} from '../../../functionality/shareButtons'

const containerVariants = {
  initial: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      delay: 0.3,
      duration: 0.8,
      staggerChildren: 0.2,
      delayChildren: 0.8,
    },
  },
}

const MotionButton = motion(Button)

const PillButton = forwardRef((props, ref) => (
  <MotionButton
    variant={'outline'}
    colorScheme={'gold'}
    borderRadius={'100px'}
    _hover={{ bg: 'hoverGold' }}
    fontWeight={'normal'}
    variants={shareButtonVariants}
    {...props}
  />
))

PillButton.displayName = 'PillButton'

export default function QuizResults({
  numCorrect,
  numQuestions,
  onRetake,
  showLinkToMainPage,
}) {
  const didWell = numCorrect >= 4
  const shareText = didWell
    ? `I scored ${numCorrect} out of ${numQuestions} on "Karma Quiz: Myth or Fact?" - I'm on the path to unraveling Karma! Take the Karma Quiz:`
    : `I scored ${numCorrect} out of ${numQuestions} on "Karma Quiz: Myth or Fact?" - looks like I have some serious reading to do... Take the Karma Quiz:`
  const url = 'https://isha.sadhguru.org/karma/quiz/'
  const hashtags = 'karmabook,karma'

  return (
    <MotionBox
      display={'flex'}
      textAlign={'center'}
      color={'gold'}
      justifyContent={'center'}
      flexDirection={'column'}
      height={'100%'}
      variants={containerVariants}
      initial={'initial'}
      animate={'show'}
      mt={4}
      gridArea={'quiz'}
      width={cardWidth}
      justifySelf={'center'}
    >
      <Heading textStyle={'heading'} mb={3}>
        You got {numCorrect} out of {numQuestions}!
      </Heading>
      <TextContainer fontSize={['md', 'xl']}>
        <p>
          {didWell ? (
            <>Wow, you're on the path to unraveling Karma!</>
          ) : (
            <>Uh-oh, looks like you've got some serious reading to do!</>
          )}
        </p>
        <p>
          <Link as={BaseLink} href={'/events'} textDecoration={'underline'}>
            Get tickets
          </Link>{' '}
          to the Karma Virtual Book Tour that includes a copy of{' '}
          <em>Karma: A Yogi’s Guide to Crafting Your Destiny</em> by Sadhguru
        </p>
        <Text as={'p'} fontSize={['xl', '2xl']}>
          Share Karma Quiz: Myth or Fact? with a friend
        </Text>
      </TextContainer>

      <ShareButtons shareText={shareText} url={url} hashtags={hashtags} />

      <HStack spacing={4} mt={4} justifyContent={'center'}>
        <PillButton onClick={onRetake} leftIcon={<RepeatIcon />}>
          Retake
        </PillButton>
        {showLinkToMainPage && (
          <PillButton as={BaseLink} href={'/'}>
            About The Book
          </PillButton>
        )}
      </HStack>
    </MotionBox>
  )
}
